const data = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    route: '/panel/dashboard',
    icon: 'appstore'
  },
  {
    key: 'detailchats',
    title: 'Chat',
    route: '/panel/chat',
    icon: 'message'
  },
  {
    key: "joiningRequests",
    title: "Joining Requests",
    route: "/panel/request/joining-requests",
    icon: "right",
    info: "Joining Requests",
  },
  {
    key: 'config',
    title: 'Configuration',
    route: '',
    icon: 'setting',
    children: [
      {
        key: 'basicInfo',
        title: 'General Settings',
        route: '/auth/post-register?page=1',
        icon: 'profile'
      },
      {
        key: 'termsAndConditions',
        title: 'Terms and conditions',
        route: '/auth/post-register?page=2',
        icon: 'global'
      },
      {
        key: 'skill',
        title: 'Update Equipment',
        route: '/auth/post-register?page=3',
        icon: 'form'
      },

      {
        key: 'addaccreditation',
        title: 'Modify Accreditation',
        route: '/auth/post-register?page=4',
        icon: 'edit'
      },
      {
        key: 'user',
        title: 'Modify Users',
        route: '/auth/post-register?page=5',
        icon: 'user'
      },
      {
        key: 'bankDetails',
        title: 'Modify Bank details',
        route: '/auth/post-register?page=6',
        icon: 'bank'
      },
      {
        key: 'audit',
        title: 'Audit',
        route: '/auth/audit',
        icon: 'global'
      }
    ]
  },
  {
    key: 'reportAndAnalytics',
    title: 'Reports & Analytics',
    route: '/',
    icon: 'highlight'
  },


];

export {data};
