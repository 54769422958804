import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AuthService} from '../../../common/services/auth.service';
import {NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {User} from '../../../common/models/user';
import {MenuService} from '../../../common/services/menu.service';
import { ChannelData } from 'stream-chat';
import { ChatDispatcherService } from 'src/app/main/chat/services/chat-dispatcher.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NzPopoverComponent, NzPopoverDirective} from 'ng-zorro-antd/popover';

import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import { VendorStatusService } from 'src/app/common/services/vendor-status.service';
import { ChangePasswordComponent } from 'src/app/auth/change-password/change-password.component';
import * as moment from 'moment';
import { StreamClientService } from 'src/app/common/services/stream-client.service';
import { CommunicationService } from 'src/app/common/services/communication.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  searchText: BehaviorSubject<string> = new BehaviorSubject<string>('');
  inputTextContent: string;
  userDetails: User;
  menuData: any[] = [];
  currentUrl = '';
  isCollapsed = false;
  showDashboard = false;
  visibleDrawer = false;
  mobileSearch: boolean = false;

  workspace = localStorage.getItem('app') === 'workspace';
  @Output() menuCollapsed?: EventEmitter<any> = new EventEmitter<any>();
  @Output() menuMouseOver?: EventEmitter<any> = new EventEmitter<any>();
  @Output() menuMouseLeave?: EventEmitter<any> = new EventEmitter<any>();
  unreadCount = 0;
  channelList: ChannelData[] = [];
  @ViewChild('inpSearch', {static: false}) inpSearch: ElementRef<any>;
  showPopover = false;
  vendorStatus: any = {};
  statusColor: any = {
    1: 'blue',
    2: 'orange',
    3: 'orange',
    4: 'red',
    5: 'green'
  }
  notifications: any[];
  subscriptions: Subscription[] = [];
  constructor(
    private authService: AuthService,
    private router: Router,
    private menuService: MenuService,
    private chatDispatcherService: ChatDispatcherService,
    private vendorStatusService: VendorStatusService,
    private modalService: NzModalService,
    private notificationService: NzNotificationService,
    private streamClientService: StreamClientService,
    public communicationService: CommunicationService
  ) {
    // @ts-ignore
    this.clearSearch();

  }
  openDestination(): void {
    if(this.vendorStatus.vendorStatus === 1) {
      this.router.navigateByUrl('/auth/post-register');
    }
    if(this.vendorStatus.vendorStatus > 1 && this.vendorStatus.vendorStatus < 5) {
      this.router.navigateByUrl('/auth/audit');
    }
  }
  ngOnInit() {
    this.userDetails = AuthService.getUserDetails();
    if(this.userDetails) {
      this.vendorStatusService.$statusChange.next(null);
    }
    this.vendorStatusService.$status.subscribe(status => {
      this.vendorStatus = status;
    })
    // this.menuData = this.menuService.getLeftMenu();
    this.currentUrl = this.router.url;
    this.showDashboard = this.router.url.includes('/auth/post-register');
    this.chatDispatcherService.notificationCounter.subscribe( subscribedData => {
      this.unreadCount = subscribedData.count;
      this.channelList = subscribedData.channelList;
    });
    // setTimeout(() => {
    //   if (this.unreadCount > 0) {
    //     this.notificationService.info('Reminder', 'You have unread messages');
    //   }
    // }, 5000);
    // @ts-ignore
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        this.currentUrl = event.url;
        this.showDashboard = event.url.includes('/auth/post-register');
      }
    });

    this.authService.authState.subscribe(loggedIn => {
      console.log('SUB LOggedIn', loggedIn);
      if (loggedIn) {
        this.userDetails = AuthService.getUserDetails();
        this.vendorStatusService.$statusChange.next(null);
      } else {
        this.userDetails = null;
      }
    });

    this.subscriptions.push(
      this.streamClientService.$allNotifications.subscribe(data => {
        this.notifications = [];
        data.notificationsByGroup.forEach(group => {
          const allActivities = group.activities.map(x => ({ ...x, is_read: group.is_read, is_seen: group.is_seen}));
          this.notifications.push( ...allActivities);
        });
      })
    )

    this.subscriptions.push(
      this.streamClientService.getRealtimeNotification().subscribe(data => {
        this.notificationService.info(data.object, moment(data.time).format('LLL'));
      })
    )

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptions.map(x => x.unsubscribe());
    // this.authService.authState.unsubscribe();
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/panel/dashboard') {
          // Dashboard from any Other Page
          console.log('In Dashboard');
          const searchKeyword = localStorage.getItem('searchKeyword');
          if (searchKeyword) {
            this.searchText.next(searchKeyword);
            this.showPopover = true;
          }
        }
      }
    });
  }

  openChat(cid) {
    this.chatDispatcherService.openChat.next(cid);
  }

  menuOpenCloseChange() {
    this.menuCollapsed.emit(this.isCollapsed);
  }

  logout() {
    this.authService.logout().then(status => {
      if (this.workspace) {
        this.router.navigateByUrl('/auth/workspace-login');
      } else {
        this.router.navigateByUrl('/auth/login');
      }
    });
  }

  profile() {
    this.router.navigateByUrl('/panel/user/profile');
  }

  goToLink(url: string) {
    console.log(url);
    this.router.navigateByUrl(url);
  }

  openCloseDrawer() {
    this.visibleDrawer = !this.visibleDrawer;
  }

  onLogoClick() {

    if (this.currentUrl.includes('/auth/login') || this.currentUrl.includes('/auth/register')) {
      window.location.href = 'https://attender.ai';
    } else {
      this.router.navigateByUrl('/panel');
    }
  }
  onSearch(text: any) {
    this.searchText.next(text.target.value);
  }

  get searchTextObservable() {
    return this.searchText as Observable<string>;
  }
  closeSearch() {
    this.showPopover = false;
  }

  clearSearch() {
    this.inputTextContent = '';
    this.searchText.next('');
    localStorage.removeItem('searchParams');
    localStorage.removeItem('searchKeyword');
    if (this.inpSearch) {

      this.inpSearch.nativeElement.focus();
    }
    // this.closeSearch();
  }

  changePassword() {
    const modal = this.modalService.create({
      nzTitle: 'Change Password',
      nzContent: ChangePasswordComponent,
      nzWidth: '975px',
      nzStyle: { top: '20px' },
      nzMaskClosable: false,
      nzComponentParams: {
      },
      nzFooter: null
    });
    modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));
    // Return a result when closed
    modal.afterClose.subscribe(result => {
    });
  }
  openSearchbar() : void{
    this.mobileSearch = !this.mobileSearch;
  }
}
