import { Component } from "@angular/core";
import { BnNgIdleService } from "bn-ng-idle";
import { environment } from "../environments/environment";
import {  OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, first, take, takeWhile } from "rxjs/operators";
import { AuthService } from "./common/services/auth.service";
import { IdleUserDetectorService } from "./common/services/idle-user-detector.service";
import { SocketService } from "./common/services/socket.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { ChangePasswordComponent } from "./auth/change-password/change-password.component";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  sessionTimeOut$: any;
  changePasswordVisible: boolean = false;
  constructor(
    private socketService: SocketService,
    private _bnIdle: BnNgIdleService,
    private _router: Router,
    private _idleUserDetectorService: IdleUserDetectorService,
    private _authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private modalService: NzModalService,
    private router : Router
  ) {}
  ngOnInit() {
    this.socketService.connect();
    this.socketService.authentication();
    this._authService.startIdleTimer();
    this.activatedRoute.queryParams.subscribe(params => {
      console.log('params FROM APP COMPONENT', this.router.url);
      if(params.changePassword) {
        if(Number(params.changePassword) === 1) {
          this.changePasswordVisible = true;
          this.openChangePasswordModal();
        }
      }
    });
  }

  openChangePasswordModal() {
    const modalRef = this.modalService.create({
      nzTitle: 'Set Password',
      nzContent: ChangePasswordComponent,
      nzComponentParams: {
        init: true
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: null
    });

    modalRef.afterClose.subscribe(result => {
      this.changePasswordVisible = false;
      this.router.navigate([], { queryParams: { changePassword: null }, queryParamsHandling: 'merge' });
    });
  }


  ngOnDestroy() {
    this._idleUserDetectorService.stop();
 }

}
